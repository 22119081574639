.swiper-button-next {
  color: red;
}

.swiper-button-prev {
  color: red;
}

.swiper-pagination-color {
    color: red;
}

.swiper-pagination-bullets {
    margin-top: 20px;
}

.swiper-pagination-bullet-active {
     background-color: red !important;
}
